import React, { Component } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import InputUnstyled from '@mui/base/InputUnstyled';
import { LinearProgress } from '@mui/material';

//dev
//const redirectURL = "https://filmstitch.auth.us-west-2.amazoncognito.com/login?client_id=181sag9cui17mpu5ht0g7494cu&response_type=token&scope=email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fadmin%2F";
//prod
const redirectURL = "https://filmstitch.auth.us-west-2.amazoncognito.com/login?client_id=181sag9cui17mpu5ht0g7494cu&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Ffilmstitch.com%2Fadmin%2F";


class Ticket extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ticketID: 0,
      loading: true,
      search: '',
      editing: false,
      rows: [this.createData('No Data', 'No Data', 'No Data', 'No Data', 'No Data')],
      activeTicket: {
        fname: 'Click a Ticket',
        org: '',
        email: '',
        phone: '',
        project: '',
        message: ''
      }
    }

    this.getGate = this.getGate.bind(this);
    this.createData = this.createData.bind(this);
    this.editChange = this.editChange.bind(this);
    this.openTicket = this.openTicket.bind(this);
    this.deleteRequest = this.deleteRequest.bind(this);
    this.makeSearch = this.makeSearch.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  //On keypress update the input field accordingly
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  componentDidMount() {
    let accessToken;

    //If token sent back in URL
    if (window.location.hash.length > 1) {
      //Get Cognito callback
      const hash = window.location.hash.substring(1);
      var result = hash.split('&').reduce(function (res, item) {
        var parts = item.split('=');
        res[parts[0]] = parts[1];
        return res;
      }, {});
      //Set token
      accessToken = result.access_token;
      //Store token in local storage
      localStorage.setItem("token", accessToken);
      this.getGate(accessToken);
      //If there is token in storage but not URL
    } else if (localStorage.getItem("token") != undefined) {
      accessToken = localStorage.getItem("token");
      this.getGate(accessToken);
    } else {
      window.location.href = redirectURL;
    }

  }

  getGate(accessToken) {

    //Set auth header for Cognito
    var authorizations = {
      headers: {
        Authorization: accessToken
      }
    };
    //Make request with SDK in public folder
    var apigClient = window.apigClientFactory.newClient();
    apigClient.authGet({}, {}, authorizations)
      .then(result => {
        if (result.status == 200) {
          if (result.data.body.Items.length > 0) {
            let dataRows = []
            for (var i = 0; i < result.data.body.Items.length; i++) {
              let iterData = result.data.body.Items;
              let date = new Date(iterData[i].date)
              dataRows.push(this.createData(iterData[i].fname, iterData[i].org, date.toDateString(), iterData[i].identity));
            }

            this.setState({
              rows: dataRows
            });
          }
        } else {
          window.location.href = redirectURL;
        }

      }
      ).catch(err => {
        console.log(err)
        window.location.href = redirectURL;
      }
      );
  }

  createData(name, org, date, link) {
    return { name, org, date, link };
  }

  editChange() {
    if (this.state.editing) {
      console.log("Put Request")
    }


    this.setState({
      editing: !this.state.editing
    })
  }

  openTicket(link) {

    this.setState({
      ticketID: link,
      loading: true
    })

    let accessToken = localStorage.getItem("token");
    //Set auth header for Cognito
    var authorizations = {
      headers: {
        Authorization: accessToken,
      }
    };
    //Make request with SDK in public folder
    var apigClient = window.apigClientFactory.newClient();
    apigClient.authOnePost({}, { idParam: link }, authorizations)
      .then(result => {
        if (result.status == 200) {
          this.setState({
            activeTicket: result.data.body.Items[0],
            loading: false
          })
        } else {
          window.location.href = redirectURL;
        }
      }
      ).catch(err => {
        console.log(err)
        window.location.href = redirectURL;
      })

  }

  deleteRequest() {
    if (window.confirm("Delete ticket: " + this.state.ticketID)) {
      console.log("delete")
      let accessToken = localStorage.getItem("token");
      //Set auth header for Cognito
      var authorizations = {
        headers: {
          Authorization: accessToken
        }
      };
      //Make request with SDK in public folder
      var apigClient = window.apigClientFactory.newClient();
      apigClient.authOneDelete({}, { "keyValue": this.state.ticketID }, authorizations)
        .then(result => {
          if (result.status) {
            console.log("success")
          } else {
            window.location.href = redirectURL;
          }
        }
        ).catch(err => {
          console.log(err);
          window.location.href = redirectURL;
        })
    }
  }

  makeSearch() {
    console.log("Searching")
    console.log(this.state.search)
    //Set auth header for Cognito
    let accessToken = localStorage.getItem("token");
    var authorizations = {
      headers: {
        Authorization: accessToken
      }
    };
    //Make request with SDK in public folder
    var apigClient = window.apigClientFactory.newClient();
    apigClient.authSearchPost({}, { "search": this.state.search }, authorizations)
      .then(result => {
        console.log(result)
        if (result.status == 200) {
          let dataRows = []
          if (result.data.body.Items.length > 0) {
              for (var i = 0; i < result.data.body.Items.length; i++) {
                let iterData = result.data.body.Items;
                let date = new Date(iterData[i].date)
                dataRows.push(this.createData(iterData[i].fname, iterData[i].org, date.toDateString(), iterData[i].identity));
              }
          }

          this.setState({
            rows: dataRows
          });

        } else {
          window.location.href = redirectURL;
        }

      }
      ).catch(err => {
        console.log(err)
        window.location.href = redirectURL;
      }
      );
  }


  handlePagination(page) {
    console.log(page.target.innerText)
  }

  render() {
    return (
      <div>
        <a href={redirectURL}>Login</a>
        <br />
        <Box sx={{ maxWidth: 1100, mx: "auto", mt: 4 }}>
          <InputUnstyled className="searchInput" name="search" onChange={this.handleChange} />
          <Button onClick={this.makeSearch} variant="contained" sx={{ bgcolor: '#871020' }}>Search</Button>
        </Box>

        <br />
        <TableContainer component={Paper} sx={{ maxWidth: 1100, mx: "auto" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Organization</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rows.map((row) => (
                <TableRow
                  onClick={() => { this.openTicket(row.link) }}
                  key={row.link}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, ":hover": { boxShadow: 6 } }}
                >
                  <TableCell component="th" scope="row">{row.name}</TableCell>
                  <TableCell align="right">{row.org}</TableCell>
                  <TableCell align="right">{row.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer>
        <br />
        <Box sx={{ maxWidth: 1100, mx: "auto" }}>
          <Pagination count={10} onChange={this.handlePagination} />
        </Box>
        <br />
        {this.state.loading ? <LinearProgress /> : <Card sx={{ minWidth: 275, maxWidth: 1100, mx: "auto" }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Ticket #{this.state.ticketID}
            </Typography>
            <Typography variant="h5" component="div">
              {this.state.activeTicket.fname}
            </Typography>
            <div>Organization: {this.state.activeTicket.org}</div>
            <div>Project: {this.state.activeTicket.project}</div>
            {!this.state.editing ? <div>Email: {this.state.activeTicket.email}</div> : <div><TextField name="email" label="email" variant="standard" sx={{ mb: 2 }} /></div>}
            {!this.state.editing ? <div>Phone: {this.state.activeTicket.phone}</div> : <div><TextField name="phone" label="phone" variant="standard" sx={{ mb: 2 }} /></div>}
            {!this.state.editing ? <div>Message: {this.state.activeTicket.message}</div> : <div><TextField name="message" label="message" variant="standard" sx={{ mb: 2 }} /></div>}

          </CardContent>
          <CardActions>
            {this.state.editing ? <Button size="small" onClick={this.editChange} >Save</Button> : <Button size="small" onClick={this.editChange} >Edit</Button>}

            <Box sx={{ marginLeft: 'auto' }}>
              <Tooltip title="Delete">
                <IconButton onClick={this.deleteRequest}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </CardActions>
        </Card>}
      </div>
    )
  }
}

export default Ticket;