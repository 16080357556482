import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import { Redirect  } from 'wouter';
import { Component } from 'react';

class TicketHome extends Component {   

    constructor(props){
        super(props);

        let emailLocal = ''
        if(localStorage.getItem("email") !== '' || localStorage.getItem("email") !== undefined){
          emailLocal = localStorage.getItem("email")
        }

        this.state = {
          fname: '',
          org: '',
          email: emailLocal,
          phone: '',
          project: '',
          message: '',
          requestMade: false,
          error: false,
          ticketID: '',
        };

    //bind event functions
    this.handleChange = this.handleChange.bind(this);
    this.postTicket = this.postTicket.bind(this);
  }

  //On keypress update the input field accordingly
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  postTicket() {
    const headers = {
      "Content-Type": "application/json; charset=UTF-8"
    }

    const requestBody = {
      "email": this.state.email,
      "message": this.state.message,
      "fname": this.state.fname,
      "org": this.state.org,
      "phone": this.state.phone,
      "project": this.state.project,
    }

    fetch('https://yog0s54ox7.execute-api.us-west-2.amazonaws.com/default/public', {
      method: 'POST',
      headers,
      body: JSON.stringify(requestBody),
    })
      .then(res => res.json())
      .then(resJSON => {
          console.log(resJSON);
          console.log("Request Made");
          if (resJSON.statusCode == 200) {
            this.setState({
              requestMade: true,
              error: false,
              ticketID: resJSON.body.ticketID,
            })
            console.log("Request Success");
          } else {
            this.setState({
              error: true,
            })
            console.log("Possible Error")
          }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          error: true,
        })
      })

  }

  render() {
    return (
      <main>
        {this.state.requestMade ? <Redirect to={'/ticket/'+this.state.ticketID} /> : ''}
        <div className="boxContentTicketHome">
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': {
                m: 1, width: '40ch'
              },
              'Button': {
                m: 1,
              },
              'h2': {
                m: 1,
              },
            }}
            noValidate
          >
            <h2>Start a Project</h2>
            <TextField
              required
              id="outlined-required"
              label="NAME"
              name="fname"
              onChange={this.handleChange}
            />
            <br />
            <TextField
              id="outlined-required"
              label="ORGANIZATION"
              name="org"
              onChange={this.handleChange}
            />
            <br />
            <TextField
              required
              id="outlined-required"
              label="EMAIL"
              name="email"
              onChange={this.handleChange}
              value = {this.state.email}
            />
            <br />
            <TextField
              required
              id="outlined-required"
              label="PHONE"
              name="phone"
              onChange={this.handleChange}
            />
            <br />
            <FormControl sx={{ m: 1, width: '40ch' }}>
              <InputLabel id="demo-simple-select-label">PROJECT</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.project}
                label="PROJECT"
                name="project"
                onChange={this.handleChange}
              >
                <MenuItem value={"photo"}>Photo</MenuItem>
                <MenuItem value={"video"}>Video</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select>
            </FormControl>
            <br />
            <TextField
              id="outlined-multiline-static"
              label="MESSAGE"
              name="message"
              required
              onChange={this.handleChange}
              multiline
              rows={4}
            />
            <br />
            <Button variant="contained" onClick={this.postTicket} sx={{ bgcolor: '#009799' }}>Submit</Button>
          </Box>
          {this.state.error? <Alert severity="error">Error</Alert> : ''}
        </div>
      </main>
    );
  }
}
export default TicketHome;