import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Component } from 'react';
import Pricedown from './Pricedown.tsx'
import Chip from '@mui/material/Chip';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

class Pricing extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>

        <Box className="priceBox">

          <Typography variant='h5'>Micro Content Plans <Chip label="Popular" color="primary" variant="outlined" /></Typography>
          <br /> 
          <Pricedown />
          <Typography>*excludes travel expenses</Typography>
          <br /><br /><br />
          <Typography variant='h5'>Sorority Recruitment Video</Typography>
          <br />
          <table className='sororityPrice'>
          <tr className='tableHeader'>
            <td></td>
            <td>Gamma Package</td>
            <td>Beta Package</td>
            <td>Alpha Package</td>
          </tr>
          <tr>
            <td>Filming Days</td>
            <td>3</td>
            <td>5</td>
            <td>10</td>
          </tr>
          <tr>
            <td>Bonus Reels (10-40s)</td>
            <td>3</td>
            <td>6</td>
            <td>10</td>
          </tr>
          <tr>
            <td>Dedicated Creative Director</td>
            <td><CloseOutlinedIcon /></td>
            <td><CheckOutlinedIcon color="primary" /></td>
            <td><CheckOutlinedIcon color="primary" /></td>
          </tr>
          <tr>
            <td>Photography Services</td>
            <td><CloseOutlinedIcon /></td>
            <td><CheckOutlinedIcon color="primary" /></td>
            <td><CheckOutlinedIcon color="primary" /></td>
          </tr>
          <tr>
            <td>Campaign Strategy</td>
            <td><CloseOutlinedIcon /></td>
            <td><CloseOutlinedIcon /></td>
            <td><CheckOutlinedIcon color="primary" /></td>
          </tr>
          <tr>
            <td>Bid Day 2023 Video Included</td>
            <td><CloseOutlinedIcon /></td>
            <td><CloseOutlinedIcon /></td>
            <td><CheckOutlinedIcon color="primary" /></td>
          </tr>
          <tr>
            <td>Targeted Regional ad Campaign</td>
            <td><CloseOutlinedIcon /></td>
            <td><CloseOutlinedIcon /></td>
            <td><CheckOutlinedIcon color="primary" /></td>
          </tr>
          <tr>
            <td>Price</td>
            <td>$1,000</td>
            <td>$2,000</td>
            <td>$3,000</td>
          </tr>
        </table>
          <br /><br /><br />
          <Typography variant='h5'>Inquire for Other Project Types or Samples</Typography>
          <br />
          <a href='/ticket'>Create Ticket</a>
        </Box>

      </div>
    );
  }
}
export default Pricing;