import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { LinearProgress } from '@mui/material';
import Alert from '@mui/material/Alert';

class Ticket extends Component {

  constructor(props) {
    super(props);

    this.state = {
      requestMade: false,
      ticketID: '',
      returnedData: ''
    };

    this.getTicket = this.getTicket.bind(this);
  }

  componentDidMount() {

    this.setState({
      ticketID: this.props.id,
    })

    this.getTicket(this.props.id);

  }

  getTicket(ticketID) {
    const headers = {
      "Content-Type": "application/json; charset=UTF-8"
    }

    fetch('https://yog0s54ox7.execute-api.us-west-2.amazonaws.com/default/public?id=' + ticketID, {
      method: 'GET',
      headers,
    })
      .then(res => res.json())
      .then(resJSON => {
        console.log("Request Made");
        if (resJSON.statusCode == 200 && resJSON.body.Count > 0) {
          this.setState({
            requestMade: true,
            error: false,
            returnedData: resJSON.body,
          })
          console.log("Request Success");
        } else {
          this.setState({
            error: true,
          })
          console.log("Possible Error")
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          error: true,
        })
      })

  }


  render() {
    return (
      <div className="boxContentTicket">
        <Card sx={{ minWidth: 275 }}>
          {this.state.error ? <Alert severity="error">Error Ticket Not Found</Alert> : <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Ticket #{this.state.ticketID}
            </Typography>
            <Typography variant="h5" component="div">
              {this.state.requestMade ? <span>Thanks for Contacing Us {this.state.returnedData.Items[0].fname}</span> : <LinearProgress />}
            </Typography>
            <br />
            <Typography variant="body2">
              We'll follow-up soon and look forward to learning more about your project.
              <br />
            </Typography>

          </CardContent>}
          <CardActions>
            <a className="noUnderline" href="mailto:info@filmstitch.com"><Button size="small">Contact Us</Button></a>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default Ticket;