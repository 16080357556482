import React, { useEffect, useRef } from "react";

export default function AutoPlaySilentVideo(props) {
    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })

    return (
    <video className={props.className} ref={videoRef} loop autoPlay muted playsInline controls>
    <source
      src={props.videoSrcOne}
      type="video/webm"
    />
    <source
      src={props.videoSrcTwo}
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>);
}