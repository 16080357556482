import React from "react";
import { Link, Route } from "wouter";
import Ticket from "./routes/Ticket";
import Home from "./routes/Home";
import TicketHome from "./routes/TicketHome";
import Admin from "./routes/Admin";
import Lookup from "./routes/Lookup";
import Pricing from "./routes/Pricing";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import InputUnstyled from '@mui/base/InputUnstyled';
import btmWaves from './img/btmWaves6.svg';
import Grid from '@mui/material/Grid';

export default function App() {

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
  };

  const setEmail = (event) => {
    localStorage.setItem("email", event.target.value);
  };

  return (
    <div>
      <AppBar position="static" sx={{ bgcolor: '#c62828' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <img className="logo" src="/FSw.png" alt="logo" />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <Link href="/">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Home</Typography>
                  </MenuItem>
                </Link>
                <Link href="/pricing">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Pricing</Typography>
                  </MenuItem>
                </Link>
                <Link href="/ticket">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Start Ticket</Typography>
                  </MenuItem>
                </Link>
                <Link href="/lookup">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">My Project Status</Typography>
                  </MenuItem>
                </Link>

              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              <img className="logo" src="/FSw.png" alt="logo" />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Link href="/">
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}>
                  Home
                </Button>
              </Link>
              <Link href="/pricing">
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}>
                  Pricing
                </Button>
              </Link>
              <Link href="/ticket">
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}>
                  Start Ticket
                </Button>
              </Link>
              <Link href="/lookup">
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}>
                  My Project Status
                </Button>
              </Link>
            </Box>

          </Toolbar>
        </Container>
      </AppBar>

      <Route path="/" component={Home} />
      <Route path="/admin" component={Admin} />
      <Route path="/ticket" component={TicketHome} />
      <Route path="/ticket/:id">
        {params => <Ticket id={params.id} />}
      </Route>
      <Route path="/lookup" component={Lookup} />
      <Route path="/pricing" component={Pricing} />

      <footer>
        <img src={btmWaves} alt="description of image" />

        <Box sx={{ flexGrow: 1, maxWidth: '1400px', ml: 'auto', mr: 'auto', spacing: 2 }}>
          <Grid container spacing={1}>
            <Grid className="topGridBox" item md={6} xs={12}>
              <h4>Start a Ticket</h4>
              <InputUnstyled className="emailInput" placeholder="test@example.com" name="email" onChange={setEmail} />
              <Link href="/ticket"><Button variant="contained" sx={{ bgcolor: '#871020' }}>Go</Button></Link>
              <br />
              <h4>Get in Touch</h4>
              <a href="mailto:info@filmstitch.com">info@filmstitch.com</a>
              <p>&#169; 2024 Film Stitch Studios</p>
            </Grid>
            <Grid className="topGridBox" item md={6} xs={12}>
              <h4>Links</h4>
              <Link href="/">Home</Link><br />
              <Link href="/ticket">Start a Ticket</Link><br />
              <Link href="/lookup">Lookup Ticket</Link><br />
              <a href="https://www.instagram.com/filmstitch/">Instagram</a><br />
              <a href="https://www.youtube.com/channel/UCxrEfmDDN6rAscB2hkVw30A">Youtube</a><br />
              <Link href="/admin">Admin</Link><br />
            </Grid>
          </Grid>
        </Box>


      </footer>


    </div>
  );
}