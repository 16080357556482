import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { Component } from 'react';
import { Redirect } from 'wouter'

class Lookup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: false,
      requestMade: false,
      renderRedirect: false
    };
    //bind event functions
    this.handleChange = this.handleChange.bind(this);
    this.postTicket = this.postTicket.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  //On keypress update the input field accordingly
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  postTicket() {

    if (this.validateEmail(this.state.email)) {



      const headers = {
        "Content-Type": "application/json; charset=UTF-8"
      }

      const requestBody = {
        "email": this.state.email
      }

      fetch('https://yog0s54ox7.execute-api.us-west-2.amazonaws.com/default/public/lookup', {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
      })
        .then(res => res.json())
        .then(resJSON => {
          console.log(resJSON);
          console.log("Request Made");
          if (resJSON.statusCode == 200) {
            this.setState({
              requestMade: true,
              error: false,
            })
            console.log("Request Success");
          } else {
            this.setState({
              error: true,
            })
            console.log("Possible Error")
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({
            error: true,
          })
        })

    } else {
      this.setState({
        renderRedirect: true
      })
    }
  }

  render() {
    return (
      <div className="boxContentLookup">
        {this.state.renderRedirect ? <Redirect to={'/ticket/' + this.state.email} /> : ''}
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': {
              m: 1, width: '40ch'
            },
            'Button': {
              m: 1,
            },
            'h2': {
              m: 1,
            }
          }}
          noValidate
        >
          <h2>Lookup a Project</h2>
          <TextField
            required
            id="outlined-required"
            label="EMAIL OR TICKET NUMBER"
            name="email"
            onChange={this.handleChange}
          />
          <br />
          <Button variant="contained" onClick={this.postTicket} sx={{ bgcolor: '#009799' }}>Submit</Button>
          <br />
          {this.state.requestMade ? <Alert severity="success">If this email address belongs to a ticket an email with a link to your dashboard will be sent shortly!</Alert> : ''}
          {this.state.error ? <Alert severity="error">Error</Alert> : ''}
          <br />
        </Box>

      </div>
    );
  }
}
export default Lookup;