import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Influencer" {...a11yProps(0)} />
          <Tab label="Brand" {...a11yProps(1)} />
          <Tab label="Enterprise" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <table className='infoTable'>
          <tr>
            <td>Max Videos per Month</td>
            <td>12</td>
          </tr>
          <tr>
            <td>Max Videos per Week</td>
            <td>3</td>
          </tr>
          <tr>
            <td>Instagram Accounts Managed</td>
            <td>1</td>
          </tr>
          <tr>
            <td>TikTok Accounts Managed</td>
            <td>0</td>
          </tr>
          <tr>
            <td>On-site Days per Month</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Branding / Graphic Design Included</td>
            <td>No</td>
          </tr>
        </table>

        <br />

        <table className='costTable'>
            <tr className='tableHeader'>
              <td>Monthly</td>
              <td>Quarterly</td>
              <td>Annually</td>
            </tr>
            <tr>
              <td className='tableFocus'>$1,500</td>
              <td>$4,500</td>
              <td>$18,000</td>
            </tr>
            <tr>
              <td>$1,350</td>
              <td className='tableFocus'>$4,050</td>
              <td>$16,200</td>
            </tr>
            <tr>
              <td>$1,200</td>
              <td>$3,600</td>
              <td className='tableFocus'>$14,400</td>
            </tr>
          </table>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <table className='infoTable'>
          <tr>
            <td>Max Videos per Month</td>
            <td>20</td>
          </tr>
          <tr>
            <td>Max Videos per Week</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Instagram Accounts Managed</td>
            <td>2</td>
          </tr>
          <tr>
            <td>TikTok Accounts Managed</td>
            <td>1</td>
          </tr>
          <tr>
            <td>On-site Days per Month</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Branding / Graphic Design Included</td>
            <td>Yes</td>
          </tr>
        </table>

        <br />

      <table className='costTable'>
            <tr className='tableHeader'>
              <td>Monthly</td>
              <td>Quarterly</td>
              <td>Annually</td>
            </tr>
            <tr>
              <td className='tableFocus'>$2,500</td>
              <td>$7,500</td>
              <td>$30,000</td>
            </tr>
            <tr>
              <td>$2,250</td>
              <td className='tableFocus'>$6,750</td>
              <td>$27,000</td>
            </tr>
            <tr>
              <td>$2,000</td>
              <td>$6,000</td>
              <td className='tableFocus'>$24,000</td>
            </tr>
          </table>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <table className='infoTable'>
          <tr>
            <td>Max Videos per Month</td>
            <td>30</td>
          </tr>
          <tr>
            <td>Max Videos per Week</td>
            <td>7</td>
          </tr>
          <tr>
            <td>Instagram Accounts Managed</td>
            <td>3</td>
          </tr>
          <tr>
            <td>TikTok Accounts Managed</td>
            <td>1</td>
          </tr>
          <tr>
            <td>On-site Days per Month</td>
            <td>4</td>
          </tr>
          <tr>
            <td>Branding / Graphic Design Included</td>
            <td>Yes</td>
          </tr>
        </table>

        <br />

      <table className='costTable'>
            <tr className='tableHeader'>
              <td>Monthly</td>
              <td>Quarterly</td>
              <td>Annually</td>
            </tr>
            <tr>
              <td className='tableFocus'>$3,500</td>
              <td>$10,500</td>
              <td>$42,000</td>
            </tr>
            <tr>
              <td>$3,150</td>
              <td className='tableFocus'>$9,450</td>
              <td>$37,800</td>
            </tr>
            <tr>
              <td>$2,800</td>
              <td>$8,400</td>
              <td className='tableFocus'>$33,600</td>
            </tr>
          </table>
      </TabPanel>
    </Box>
  );
}
