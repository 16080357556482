import * as React from 'react';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';


const steps = [
    {
        description: `“FilmStitch did phenomenal work running our most recent ad campaign. They were extremely communicative and exceeded all expectations.”`,
    },
    {
        description: `“We’ve gone from agency to agency searching for a company like this. Thank you Filmstitch for taking our brand to the next level!”`,
    },
    {
        description: `“Get ready for results with these guys. They walked me through every part of their strategy and made sure I was kept in the loop. Results speak for themselves.”`,
    },
    {
        description: `“FilmStitch created video that beautifully introduced our new product lineup to retailers.”`,
    },
];

const theme = createTheme({
    components: {
        // Name of the component
        MuiMobileStepper: {
            styleOverrides: {
                // Name of the slot
                root: {
                    backgroundColor: 'inherit',
                    marginBottom: 40
                },
                dot: {
                    // Some CSS
                    backgroundColor: 'gray',
                },
                dotActive: {
                    backgroundColor: '#1976d2'
                }
            },
        },
    },
});

export default function TextMobileStepper() {
    //const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{ maxWidth: 450, width: '100%', flexGrow: 1 }}>

            <Box sx={{ height: 'auto', maxWidth: 450, width: '100%', p: 2 }}>
                {steps[activeStep].description}
            </Box>
            <ThemeProvider theme={theme}>
                <MobileStepper
                    // style={{ backgroundColor: 'inherit' }}
                    variant="dots"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >

                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                        </Button>
                    }
                />
            </ThemeProvider>
        </Box>
    );
}
