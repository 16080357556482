import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Redirect } from 'wouter';
import { Component } from 'react';
import InputUnstyled from '@mui/base/InputUnstyled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import topWaves from '../img/topWaves2.svg';
import midWaves from '../img/mediumWaves4.svg';
import midWaves2 from '../img/mediumWaves3.svg';
import video from '../img/MAIN.webm';
import videoMP from '../img/MAIN.mp4';
import logo1 from '../img/1.png';
import logo2 from '../img/2.png';
import logo3 from '../img/3.png';
import logo4 from '../img/4.png';
import logo5 from '../img/5.png';
import logo6 from '../img/6.png';
import logo7 from '../img/7.png';
import logo8 from '../img/8.png';
import logo9 from '../img/9.png';
import logo10 from '../img/10.png';
import AutoPlaySilentVideo from './AutoPlayVideo';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import PhonelinkOutlinedIcon from '@mui/icons-material/PhonelinkOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import TextMobileStepper from './Testamonial.tsx'
import Iframe from 'react-iframe'

class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      redirect: false,
    };
    //bind event functions
    this.handleChange = this.handleChange.bind(this);
    this.passTicket = this.passTicket.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  passTicket() {
    this.setState({ redirect: true })
    localStorage.setItem("email", this.state.email);
  }

  doRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={'/ticket'} />
    }
  }

  render() {
    return (
      <main>
        {this.doRedirect()}
        <div className="topBackground">
          <Box sx={{ flexGrow: 1, maxWidth: '1400px', ml: 'auto', mr: 'auto', spacing: 2 }}>
            <Grid container spacing={1}>
              <Grid className="topGridBox" item md={6} xs={12}>
                <AutoPlaySilentVideo videoSrcOne={video} videoSrcTwo={videoMP} />
              </Grid>
              <Grid className="topGridBox" item md={6} xs={12}>
                <div className="topGridTitle">
                  <Typography className="hookText" variant="h4" fontWeight='bold'>Film. Stitch. Done.</Typography>
                  <Typography variant="h6">
                    You bring the vision, we do the rest. Enter your email to get started.
                  </Typography>
                  <br />
                  <InputUnstyled className="emailInput" placeholder="test@example.com" name="email" onChange={this.handleChange} />
                  <Button variant="contained" onClick={this.passTicket} sx={{ bgcolor: '#871020' }}>Go</Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className="midBackgroundOne">
          <img src={topWaves} alt="waves" />

          <Card className="videoCard" sx={{ borderRadius: 4, color: 'white' }}>
            <CardContent>
              <Typography variant="h5" fontWeight="bold" component="div">
                Content Production
              </Typography>
              <Typography variant="h6">
                Let our team effortlessly collaborate with you and
                your organization to create stunning video content.
              </Typography>
              <br />
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={6}>
                  <VideocamOutlinedIcon color="white" /> 1000+ Videos Delivered
                </Grid>
                <Grid item xs={6}>
                  <PlayArrowOutlinedIcon color="white" /> 40M+ Views
                </Grid>
                <Grid item xs={6}>
                  <CameraAltOutlinedIcon color="white" /> Photo / Video Services For Any Occasion
                </Grid>
                <Grid item xs={6}>
                  <SellOutlinedIcon color="white" /> Proven ROI
                </Grid>
              </Grid>

            </CardContent>
          </Card>

          <Card className="videoCard" sx={{ borderRadius: 4, color: 'white' }}>
            <CardContent>
              <Typography variant="h5" fontWeight="bold" component="div">
                Marketing
              </Typography>
              <Typography variant="h6">
                Full service account and campaign management.
              </Typography>
              <br />
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={6}>
                  <PhonelinkOutlinedIcon color="white" /> Comprehensive Social Media Management
                </Grid>
                <Grid item xs={6}>
                  <TuneOutlinedIcon color="white" /> Tailored Content Production
                </Grid>
                <Grid item xs={6}>
                  <AnalyticsOutlinedIcon color="white" /> Metrics Reporting and Optimization
                </Grid>
                <Grid item xs={6}>
                  <DashboardCustomizeOutlinedIcon color="white" /> Lead Generation
                </Grid>
              </Grid>

            </CardContent>
          </Card>

        </div>

        <div className="midBackgroundTwo">
          <img src={midWaves2} alt="waves" />

          <Box className="clientBox">
            <TextMobileStepper />

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <img src={logo1} alt='logo' />
              </Grid>
              <Grid item xs={4}>
                <img src={logo2} alt='logo' />
              </Grid>
              <Grid item xs={4}>
                <img src={logo3} alt='logo' />
              </Grid>
              <Grid item xs={3}>
                <img src={logo4} alt='logo' />
              </Grid>
              <Grid item xs={3}>
                <img src={logo5} alt='logo' />
              </Grid>
              <Grid item xs={3}>
                <img src={logo6} alt='logo' />
              </Grid>
              <Grid item xs={3}>
                <img src={logo7} alt='logo' />
              </Grid>
              <Grid item xs={4}>
                <img src={logo8} alt='logo' />
              </Grid>
              <Grid item xs={4}>
                <img src={logo9} alt='logo' />
              </Grid>
              <Grid item xs={4}>
                <img src={logo10} alt='logo' />
              </Grid>
            </Grid>
            <Typography fontWeight='bold'>and many more</Typography>

          </Box>

        </div>
        <div className="btmBackground">
          <img src={midWaves} alt="waves" />

          <Box className="ifWrapper" sx={{ maxWidth:'100%', width: { xs: '400px', md: '650px' }, height: { xs: '450px', md: '650px' } }}>
            <Iframe url="https://www.instagram.com/filmstitch/embed/"
              className="igEmbed"
              scrolling='no' />
          </Box>
        </div>

      </main>
    );
  }
}
export default Home;